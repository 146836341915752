import { render, staticRenderFns } from "./sidebarTags.vue?vue&type=template&id=6dc40f17&scoped=true&"
import script from "./sidebarTags.vue?vue&type=script&lang=js&"
export * from "./sidebarTags.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc40f17",
  null
  
)

export default component.exports